@keyframes fiestasPatriasAnimation {
  0% {
    border-color: blue;
  }
  50% {
    border-color: white;
  }
  100% {
    border-color: red;
  }
}

.fiestasPatrias-border {
  border: 2px solid blue;
  animation: fiestasPatriasAnimation 2s infinite;
}
